<template>
  <div>Страница типографии</div>
</template>

<script>
export default {
  name: "TypographyPage"
};
</script>

<style scoped lang="scss"></style>
